<div fxLayout="row" class="mainDiv" style="align-items: center;justify-content: center;display: flex;">
    <p class="titletext1 welcomepagetitle">શ્રીસ્વામિનારાયણ ક્વિઝ ગેમ કુંડળધામ <br><span style="color: #89ff00;">Please
            Wait</span></p>
    <div fxFlex="50" class="col-sm-12" style="text-align: center;" appNoRightClick>
        <img class="img" src="../../assets/Images/Intro_Page_Kundal.png" style="width: 250px;">
        <p class="titletext1">શ્રીસ્વામિનારાયણ ભગવાન</p>
    </div>
    <div fxFlex="50" class="col-sm-12" style="text-align: center;" appNoRightClick>
        <img class="img" src="../../assets/Images/Guruji_Mobile_Main_Page.png" style="width: 250px;">
        <p class="titletext">પ્રેરક : પ.પૂ. સદ્દ. શ્રીજ્ઞાનજીવનદાસજી સ્વામી</p>
    </div>
</div>
<div style="display: none;">
    <div *ngFor="let img of quizData">
        <img src="{{img.question_media}}" width="1" height="1" border="0">
        <audio *ngIf="img.audio_URL" src="{{img.audio_URL}}" width="1" height="1" border="0"></audio>
    </div>
</div>