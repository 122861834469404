import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SocketioService } from './socketio.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { QuizStartComponent } from './quiz-start/quiz-start.component';
import { QuizScreenComponent } from './quiz-screen/quiz-screen.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { AnswerScreenComponent } from './answer-screen/answer-screen.component';
import { FinalScreenComponent } from './final-screen/final-screen.component';
import { BeforeResultComponent } from './before-result/before-result.component';
import { WaitScreennComponent } from './wait-screenn/wait-screenn.component';
import { RefreshScreenComponent } from './refresh-screen/refresh-screen.component';
import { RestrictRightClickDirective } from './restrict-right-click.directive';
import { PathLocationStrategy } from '@angular/common';
import { QuestionOnlyComponent } from './question-only/question-only.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';

@NgModule({
  declarations: [
    AppComponent,
    QuizStartComponent,
    QuizScreenComponent,
    AnswerScreenComponent,
    FinalScreenComponent,
    BeforeResultComponent,
    WaitScreennComponent,
    RefreshScreenComponent,
    RestrictRightClickDirective,
    QuestionOnlyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularMaterialModule,
    NgxAudioPlayerModule
  ],
  providers: [SocketioService, PathLocationStrategy],
  bootstrap: [AppComponent],
})
export class AppModule {}
