import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RouteHelper } from '../services/route-helper';

@Injectable({
  providedIn: 'root',
})
export class DirectAccessGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // If Route is directly added in browser, isNextPage flag will be false
    if (!RouteHelper.isNextPage) {
      this.router.navigate(['/refresh-screen']); // Navigate to refresh page
      return false;
    } else {
      RouteHelper.isNextPage = false;
    }
    return RouteHelper.isNextPage || true;
  }
}
