<form class="mainDiv" fxLayoutAlign="center center" [formGroup]="loginForm" (submit)="submit()">
  <mat-card fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field appearance="outline" fxFlex="55">
        <mat-label>નામ </mat-label>
        <input matInput formControlName="name" required />
        <mat-error *ngIf=" loginForm.controls.name.touched && loginForm.controls.name.invalid">
            <span *ngIf="loginForm.controls.name.errors.required">નામ ફરજિયાત છે.</span>
        </mat-error>
        <mat-error *ngIf=" loginForm.controls.name.touched && loginForm.controls.name.invalid">
            <span *ngIf="loginForm.controls.name.errors.maxLength">નામ 20 અક્ષરો કરતા ઓછું હોવું જોઈએ.</span>
        </mat-error>
      </mat-form-field>
      <mat-error fxFlex="55">
        <span *ngIf="userNameAlreadyExists">નામ પહેલેથી જ અસ્તિત્વમાં છે, કૃપા કરીને અલગ નામ પસંદ કરો</span>
      </mat-error>
      <mat-card-actions fxFlex="30">
        <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">Login</button>
      </mat-card-actions>
    </div>
  </mat-card>
</form>