import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-final-screen',
  templateUrl: './final-screen.component.html',
  styleUrls: ['./final-screen.component.css'],
})
export class FinalScreenComponent implements OnInit {
  finalRank: number;
  finalScore: number;

  constructor(
    private locationStrategy: LocationStrategy,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.preventBackButton();
    this.finalRank = this.dataService.userRank;
    this.finalScore = this.dataService.userScore;
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }
}
