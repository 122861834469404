import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-before-result',
  templateUrl: './before-result.component.html',
  styleUrls: ['./before-result.component.css'],
})
export class BeforeResultComponent implements OnInit, OnDestroy {
  slogans: string[] = [
    'સાવધાન !!!!!!',
    'આ વખતે રાજીપો લેવો જ છે.',
    'વિચારીને જવાબ આપીશ.',
    'હું ખોટો જવાબ તો નહીં જ આપું.',
    'મારો નંબર આવશે તો મહારાજ રાજી થશે.',
    'હું સજગતા રાખીશ કદાચ પૂ. ગુરુજી જોતા હશે.',
    'મારો જવાબ જોઈને ટપુજી રાજી થશે.',
    'મારે સંતોને રાજી કરવા જ છે.',
    'હું રમવામાં નીતિ રાખીશ.',
    'હવે તો વ્યવસ્થિત  તૈયારી કરીશ',
    'આજે મારે મહારાજને રાજી કરવા જ છે.',
    'મહારાજ સતત મારી ઉપર પ્રેમ વરસાવી રહ્યા છે.',
    'હું મહારાજનો અને મહારાજ મારા.',
    'મહારાજને રાજી કરવા ફરી વાર હું જરૂર ધ્યાન રાખીશ.',
    'મારે પ્રભુના પ્યારા થવું જ છે.',
    'મહારાજ ફરી વાર હું ધ્યાન રાખીશ હો.',
    'જો જો મહારાજ આપણી સાથે જ રમી રહ્યા છે હો.',
    'મારે પૂ.ગુરુજીના લાડલા બનવાનું છે.',
    'પૂ.ગુરુજીનો પ્યારો બનવા માટે રમીશ.',
    'હું સંતોનો ભૂલકો છું.',
    'વ્હાલા ફરી વાર ધ્યાન રાખજે હો.',
    'મારા લાડલા ધ્યાનથી રમજે હો.',
    'તું પૂ.ગુરુજીનો લાડલો છો હો.',
    'તું સંતોનો પ્યારો છો હો.',
    'મારે સંતો-ભક્તોના પ્યારા બનવું છે.',
    'મહારાજને સાથે રાખીને રમજે હો.',
    'આ તો અક્ષરધામની રમત છે હો.',
    'આનાથી તો પ્રભુના પ્યારા બનાશે હો.',
    'આનાથી તો ગુરુજીના લાડલા બનાશે હો.',
    'હું રમું તે ઘનું ને બહુ ગમે.',
  ];

  Images: string[] = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
  ];

  currentImage = this.Images[Math.floor(Math.random() * this.Images.length)];
  currentSlogan = this.slogans[Math.floor(Math.random() * this.slogans.length)];
  showResultSub: Subscription;
  constructor(
    private socketService: SocketioService,
    private router: Router,
    private locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
    this.showResultSub = this.socketService.ShowResultScreen().subscribe(() => {
      this.router.navigate(['/answer-screen']);
    });
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  ngOnDestroy(): void {
    this.showResultSub.unsubscribe();
  }
}
