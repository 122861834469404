import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { quizData, VideoQues, AudioQues } from "../interfaces/QuizData";
import { DataService } from "../services/data.service";
import { RouteHelper } from "../services/route-helper";
import { SocketioService } from '../socketio.service';
import { Track } from 'ngx-audio-player';
import { WindowSizeService } from "../services/window-size.service";

@Component({
  selector: 'app-question-only',
  templateUrl: './question-only.component.html',
  styleUrls: ['./question-only.component.css'],
})
export class QuestionOnlyComponent implements OnInit, OnDestroy {
  quizStartedSub: Subscription;
  currentQuizQuestion: quizData;
  currentNumber: number;
  videoQues = VideoQues;
  audioQues = AudioQues;
  safeURL: SafeResourceUrl = '';
  msaapDisplayTitle = false;
  msaapDisplayVolumeControls = true;
  msaapDisplayArtist = false;
  msaapDisplayDuration = true;
  msaapDisplayRepeatControls = true;
  msaapPlaylist: Track[] = [];
  isAudioVideo = false;
  isDesktop = false;

  player: any;

  constructor(
    private dataService: DataService,
    private router: Router,
    private socketService: SocketioService,
    private _sanitizer: DomSanitizer,
    private windowService: WindowSizeService
  ) {}

  ngOnInit(): void {
    this.windowService.isDesktop.subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
    });

    this.currentQuizQuestion = this.dataService.quizData?.quizData[
        this.dataService.currentQuestion == undefined
          ? 0
          : this.dataService.currentQuestion
      ];
      this.currentNumber =
        this.dataService.currentQuestion == undefined
          ? 1
          : this.dataService.currentQuestion + 1;

    if (this.currentQuizQuestion?.show_video != VideoQues.noVieo) {
      this.currentQuizQuestion.video_URL =
          this.currentQuizQuestion.video_URL + '&enablejsapi=1';
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.currentQuizQuestion?.video_URL
      );
      this.isAudioVideo = true;
    }

    if (
        this.currentQuizQuestion?.show_audio == this.audioQues.beforeQuestion ||
        this.currentQuizQuestion?.show_audio ==
          this.audioQues.beforeQuestionAudioOnly
      ) {
        this.msaapPlaylist.push({
          title: 'Live Quiz',
          link: this.currentQuizQuestion?.audio_URL,
        });
        this.isAudioVideo = true;
      }

    this.playBellNotification();

    this.init();

    this.quizStartedSub = this.socketService
      .getQuizStartedByAdmin()
      .subscribe(() => {
        RouteHelper.isNextPage = true;
        this.router.navigate(['/quiz-screen']);
      });
  }

  playBellNotification() {
    let audio = new Audio();
    audio.src = "../../assets/audio/mixkit-bell-notification-933.wav";
    audio.load();
    audio.muted = false;
    audio.play();
  }

  init() {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }

  startVideo() {
    this.player = new window['YT'].Player('iFrameID', {
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    });
  }

  onPlayerStateChange(event) {
    switch (event.data) {
      case window['YT'].PlayerState.ENDED:
        this.player.loadVideoByUrl({
          mediaContentUrl: this.currentQuizQuestion.video_URL,
          startSeconds: this.currentQuizQuestion?.video_start,
          endSeconds: this.currentQuizQuestion?.video_end,
        });
        this.player.pauseVideo();
        break;
    }
  }

  ngOnDestroy() {
    this.quizStartedSub.unsubscribe();
    this.msaapPlaylist = [];
    window['YT'] = null;
  }
}