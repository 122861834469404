import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { quizData } from '../interfaces/QuizData';
import { DataService } from '../services/data.service';
import { RouteHelper } from '../services/route-helper';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-wait-screenn',
  templateUrl: './wait-screenn.component.html',
  styleUrls: ['./wait-screenn.component.css', '../plugin-style.css'],
})
export class WaitScreennComponent implements OnInit, OnDestroy {
  sub: Subscription;
  showFinal: Subscription;
  showNextSub: Subscription;
  quizData: quizData[];

  constructor(
    private socketService: SocketioService,
    private router: Router,
    private locationStrategy: LocationStrategy,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
    this.quizData = this.dataService.quizData?.quizData;
    this.sub = this.socketService.getQuizStartedByAdmin().subscribe(() => {
      RouteHelper.isNextPage = true;
      this.router.navigate(['/quiz-screen']);
    });

    this.showNextSub = this.socketService.ShowNextQuestion().subscribe(() => {
      RouteHelper.isNextPage = true;
      this.router.navigate(['/question-only']);
    });

    this.showFinal = this.socketService.ShowFinalScreen().subscribe(() => {
      this.router.navigate(['/final-screen']);
    });
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.showFinal.unsubscribe();
    this.showNextSub.unsubscribe();
  }
}
