import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocketioService } from './socketio.service';
import { QuizData } from './interfaces/QuizData';
import { Router } from '@angular/router';
import { DataService } from './services/data.service';
import { Subscription } from 'rxjs';
import { RouteHelper } from './services/route-helper';
import { PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'socketio-angular';
  quizData: QuizData;
  isWaitScreen: boolean = false;
  quizStatusSub: Subscription;
  //waitText = 'ક્વીઝ થોડીવારમાં શરૂ થશે, રાહ જુવો';
  waitText = 'જય સ્વામિનારાયણ';
  waitText1 = 'સત્સંગ ક્વીઝ: શ્રીસ્વામિનારાયણ રાસ - 3';
  waitText2 = 'ક્વિઝ શરૂ થવા સુધિ રાહ જોવા વિનંતિ.';
  quizIdFromParam;
  currentQuizId = 'EkadashiQuiz';
  constructor(
    private socketService: SocketioService,
    private router: Router,
    private dataService: DataService,
    pathLocationStrategy: PathLocationStrategy
  ) {
    RouteHelper.isNextPage = false;
    const absolutePathWithParams = pathLocationStrategy.path();
    if (absolutePathWithParams.indexOf('quizId') != -1) {
      this.quizIdFromParam = absolutePathWithParams.substr(
        absolutePathWithParams.indexOf('=') + 1
      );
    }
  }

  ngOnInit(): void {
    const quizId = localStorage.getItem('quizId');
    if (quizId == null && this.quizIdFromParam != this.currentQuizId) {
      this.manageInvalidURL();
    } else if (
      quizId != null &&
      quizId != this.currentQuizId &&
      this.quizIdFromParam != this.currentQuizId
    ) {
      this.manageInvalidURL();
    } else {
      if (quizId == null) {
        localStorage.setItem('quizId', this.quizIdFromParam);
      } else if (quizId != null && this.quizIdFromParam == this.currentQuizId) {
        localStorage.setItem('quizId', this.quizIdFromParam);
      }
      this.socketService.setUpSocketConnection();

      this.quizStatusSub = this.socketService
        .getQuizStatus()
        .subscribe((msg: QuizData) => {
          var user = localStorage.getItem('user');
          if (msg.quizStatus == 'false') {
            if (user != null) {
              localStorage.clear();
            }
          } else {
            this.quizData = msg;
            this.dataService.quizData = msg;
            if (this.quizData?.quizStatus == 'true' && user == null) {
              this.router.navigate(['/start-screen']);
            } else if (this.quizData?.quizStatus == 'true' && user != null) {
              const userName = localStorage.getItem('user');
              const todaysDate = new Date().toDateString();
              const storedQuizDate = localStorage.getItem('QuizDate');
              if (storedQuizDate == null || storedQuizDate != todaysDate) {
                localStorage.removeItem('user');
                localStorage.removeItem('QuizDate');
                this.router.navigate(['/start-screen']);
              } else {
                //User is already present, get its details and set in dataService
                this.socketService.getCurrentQuestion(userName);
              }
            }
          }
        });

      this.socketService.CurrentQuestionFromServer().subscribe((obj) => {
        var userObj = obj.userObj;
        var currentNo = obj.currentQNo;
        if (userObj == null) {
          // user exists in localStorage, but not in redis cache : Clear storage and redirect to start screen
          localStorage.removeItem('user');
          this.router.navigate(['/start-screen']);
        } else {
          this.dataService.currentQuestion = currentNo;
          this.dataService.userName = userObj.name;
          this.dataService.playerTotalScore = userObj.score;
          this.dataService.answerStreak = userObj.answerStreak;
          if (currentNo == 0) {
            this.router.navigate(['/wait-screen']);
          } else {
            this.router.navigate(['/refresh-screen']);
          }
        }
      });
    }
  }

  manageInvalidURL(): void {
    this.waitText = 'Invalid URL';
    this.waitText1 = '';
    this.waitText2 = '';
    localStorage.clear();
  }

  ngOnDestroy(): void {
    this.quizStatusSub.unsubscribe();
  }
}
