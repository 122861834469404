import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RouteHelper } from '../services/route-helper';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-refresh-screen',
  templateUrl: './refresh-screen.component.html',
  styleUrls: ['./refresh-screen.component.css'],
})
export class RefreshScreenComponent implements OnInit {
  refreshText: string = 'આગળના પ્રશ્ન માટે રાહ જુવો';
  sub: Subscription;
  showFinal: Subscription;
  showNextSub: Subscription;

  constructor(
    private locationStrategy: LocationStrategy,
    private socketService: SocketioService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
    this.sub = this.socketService.getQuizStartedByAdmin().subscribe(() => {
      RouteHelper.isNextPage = true;
      this.router.navigate(['/quiz-screen']);
    });

    this.showNextSub = this.socketService.ShowNextQuestion().subscribe(() => {
      RouteHelper.isNextPage = true;
      this.router.navigate(['/question-only']);
    });

    this.showFinal = this.socketService.ShowFinalScreen().subscribe(() => {
      this.router.navigate(['/final-screen']);
    });
  }

  preventBackButton(): void {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.showFinal.unsubscribe();
    this.showNextSub.unsubscribe();
  }
}
