import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { RouteHelper } from '../services/route-helper';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-answer-screen',
  templateUrl: './answer-screen.component.html',
  styleUrls: ['./answer-screen.component.css'],
})
export class AnswerScreenComponent implements OnInit, OnDestroy {
  isAnswerCorrectB: boolean;
  answerStreak: number;
  currentPoints: number;
  totalScore: number;
  userName: string;
  isTimeUp: boolean;
  isPartiallyCorrect: boolean;
  showNextSub: Subscription;
  showFinal: Subscription;
  quizStartedSub: Subscription;
  selfNameIndex;
  selfScore;
  selfRank;
  otherName;
  scoreDiff = 0;

  correctQuotes: string[] = [
    '"Good is not good when better is expected"',
    '"Champions keep playing until they get it right"',
    '"You were born to be a winner"',
    '"Just Keep going"',
    '"Set your goals high, and don’t stop till you get there"',
    '"Winning isnt everything--but wanting to win is"',
    '"Its easy to win. Anybody can win"',
    '"Be willing and you will be winning"',
    '"A winner never stops trying"',
    '"We aim above the mark to hit the mark"',
  ];

  inCorrectQuotes: string[] = [
    '"Never give up !"',
    '"Keep Moving Forward."',
    '"You never fall until you stop trying"',
    '"You are one day closer to your goal."',
    '"You’re never a loser until you quit trying."',
    '"You can’t win unless you learn how to lose."',
    '"You rarely win, but sometimes you do."',
    '"You do not enter a race to lose."',
    '"You have to do right thing at right time."',
    '"Do or do not. There is no try."',
  ];

  currentCorrectSlogan = this.correctQuotes[
    Math.floor(Math.random() * this.correctQuotes.length)
  ];
  currentInCorrectSlogan = this.inCorrectQuotes[
    Math.floor(Math.random() * this.inCorrectQuotes.length)
  ];

  constructor(
    private router: Router,
    private dataService: DataService,
    private socketService: SocketioService,
    private locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
    this.isAnswerCorrectB =
      this.dataService.playerCurrentScore === 0 ? false : true;
    this.answerStreak = this.dataService.answerStreak;
    this.currentPoints = this.dataService.playerCurrentScore;
    this.totalScore = this.dataService.playerTotalScore;
    this.userName = this.dataService.userName;
    this.isTimeUp = this.dataService.isTimeUp;
    this.isPartiallyCorrect = this.dataService.isPartiallyCorrect;

    this.socketService.GetUserRankCall().subscribe((obj: any[]) => {
      if (obj.indexOf(this.dataService.userName) != -1) {
        this.selfNameIndex = obj.indexOf(this.dataService.userName);
        this.selfScore = obj[this.selfNameIndex + 1];
        this.selfRank = this.selfNameIndex / 2 + 1;

        if (this.selfNameIndex != 0) {
          this.otherName = obj[this.selfNameIndex - 2];
          var otherScore = obj[this.selfNameIndex - 1];
          this.scoreDiff = otherScore - this.selfScore;
        }
        this.dataService.userRank = this.selfRank;
        this.dataService.userScore = this.selfScore;
      }
    });

    this.showNextSub = this.socketService.ShowNextQuestion().subscribe(() => {
      RouteHelper.isNextPage = true;
      this.router.navigate(['/question-only']);
    });

    this.showFinal = this.socketService.ShowFinalScreen().subscribe(() => {
      this.router.navigate(['/final-screen']);
    });

    // this.quizStartedSub = this.socketService
    //   .getQuizStartedByAdmin()
    //   .subscribe(() => {
    //     RouteHelper.isNextPage = true;
    //     this.router.navigate(['/quiz-screen']);
    //   });
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  ngOnDestroy(): void {
    this.showNextSub.unsubscribe();
    this.showFinal.unsubscribe();
    //this.quizStartedSub.unsubscribe();
  }
}
