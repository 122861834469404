import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnswerScreenComponent } from './answer-screen/answer-screen.component';
import { BeforeResultComponent } from './before-result/before-result.component';
import { FinalScreenComponent } from './final-screen/final-screen.component';
import { DirectAccessGuard } from './guards/access.guard';
import { QuestionOnlyComponent } from './question-only/question-only.component';
import { QuizScreenComponent } from './quiz-screen/quiz-screen.component';
import { QuizStartComponent } from './quiz-start/quiz-start.component';
import { RefreshScreenComponent } from './refresh-screen/refresh-screen.component';
import { WaitScreenComponent } from './wait-screen/wait-screen.component';
import { WaitScreennComponent } from './wait-screenn/wait-screenn.component';

const routes: Routes = [
  { path: 'wait-screen', component: WaitScreennComponent },
  { path: 'start-screen', component: QuizStartComponent },
  {
    path: 'quiz-screen',
    component: QuizScreenComponent,
    canActivate: [DirectAccessGuard],
  },
  { path: 'answer-screen', component: AnswerScreenComponent },
  { path: 'final-screen', component: FinalScreenComponent },
  { path: 'before-result', component: BeforeResultComponent },
  { path: 'refresh-screen', component: RefreshScreenComponent },
  { path: 'question-only', component: QuestionOnlyComponent },
  { path: '**', component: QuizStartComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
