import { Injectable } from '@angular/core';
import { QuizData } from '../interfaces/QuizData';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public isWaitScreen: boolean = false;
  public quizData: QuizData;
  public currentQuestion: number = 0;
  public playerTotalScore: number = 0;
  public playerCurrentScore: number = 0;
  public userName: string;
  public userId: string;
  public answerStreak: number = 0;
  public isTimeUp: boolean = false;
  public isPartiallyCorrect: boolean = false;
  public userRank: number = 0;
  public userScore: number = 0;

  constructor() {}
}
