<div fxLayout="column" class="mainDiv">
  <div class="noQuizImgDiv" *ngIf="quizData?.quizStatus != 'true'" fxLayout="row wrap" fxLayoutAlign="center center"
    fxLayoutGap.xs="0">
    <span class="textSpan">
      {{this.waitText}}<br>{{this.waitText1}}<br>{{this.waitText2}}
    </span>
  </div>

  <!-- <div *ngIf="quizData?.quizStatus">
    <app-quiz-start></app-quiz-start>
  </div> -->

  <div *ngIf="quizData?.quizStatus == 'true'" class="routerDiv">
    <router-outlet></router-outlet>
  </div>
</div>