import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-quiz-start',
  templateUrl: './quiz-start.component.html',
  styleUrls: ['./quiz-start.component.css'],
})
export class QuizStartComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  userNameAlreadyExists: boolean = false;
  userNameStatusSub: Subscription;

  constructor(
    private formsBuilder: FormBuilder,
    private socketService: SocketioService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
    this.loginForm = this.formsBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(20)]],
    });

    this.userNameStatusSub = this.socketService
      .getUserNameStatus()
      .subscribe((msg: any) => {
        this.userNameAlreadyExists = msg.isExisting;
        if (!msg.isExisting) {
          this.dataService.isWaitScreen = true;
          this.dataService.userName = this.loginForm.controls.name.value;
          this.dataService.userId = msg.uID;
          this.dataService.currentQuestion = msg.currentQNo;
          localStorage.setItem('user', msg.uID);
          localStorage.setItem('QuizDate', new Date().toDateString());
          this.router.navigate(['/wait-screen']);
        }
      });
  }

  submit(): void {
    if (!this.loginForm.valid) {
      return;
    }
    this.socketService.checkUserName(this.loginForm.value.name);
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  ngOnDestroy(): void {
    this.userNameStatusSub.unsubscribe();
  }
}
