export interface QuizData {
  quizStatus: string;
  quizData: quizData[];
}

export interface quizData {
  question_id: number;
  id: number;
  question_type: number;
  answer_type: string;
  question_text: string;
  show_media_before_image: boolean;
  question_media: string;
  show_video: VideoQues;  
  video_URL: string;
  video_start: number;
  video_end: number;
  show_audio: AudioQues;
  audio_URL: string;
  correct_answer: string;
  option_1: string;
  option_2: string;
  option_3: string;
  option_4: string;
  credit_coin: number;
  time_in_sec: number;
  created_by: number;
}

export enum VideoQues {
  noVieo = 0,
  beforeQuestion = 1,
  duringQuestion = 2,
  afterQuestion = 3,
  beforeQuestionVideoOnly = 4,
}

export enum AudioQues {
  noAudio = 0,
  beforeQuestion = 1,
  duringQuestion = 2,
  afterQuestion = 3,
  beforeQuestionAudioOnly = 4,
}