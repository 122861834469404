import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService {

  public isMobile: Observable<boolean>;
  public isDesktop: Observable<boolean>;
  public isTablet: Observable<boolean>;
  public isMenuCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPlatformBrowser;

  constructor(private observer: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.isMobile = observer.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset
    ]).pipe(map((result) => result.matches));

    this.isDesktop = observer.observe([
      Breakpoints.Web,
      Breakpoints.WebLandscape,
      Breakpoints.WebPortrait
    ]).pipe(map((result) => result.matches));

    this.isTablet = observer.observe([
      Breakpoints.Tablet,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait
    ]).pipe(map((result) => result.matches));

    this.isPlatformBrowser = isPlatformBrowser(this.platformId);
  }  
}

