<div fxLayout="column" class="finalMainDiv">
    <canvas id="confetti"></canvas>
    <div class="finalTextDiv grid" fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="winner-wrap">
            <div class="border"></div>
            <div class="medal-box"><i class="fas fa-medal"></i></div>
            <h1>Thanks for playing Satsang Quiz</h1>
            <h2>Your Score is : {{finalScore}}</h2>
            <div class="winner-ribbon">Your Rank is : {{finalRank}}</div>
            <div class="right-ribbon"></div>
            <div class="left-ribbon"></div>
        </div>
    </div>
</div>