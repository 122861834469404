<div fxLayout="column" fxLayoutGap="5px">
    <div style="height: 80px; background-color:#eee;" class="center-center" fxLayout="row"
        fxLayoutAlign="center center">
        <span fxLayoutAlign="center center" fxFlex="15" class="questionHeader1"
            style="font-size: 15px;font-weight: 500;height: 54px;width: 54px;min-width: 54px;border: 1px solid #9E9E9E;border-radius: 50%;margin-left: 10px;">{{+currentQuestion + 1}}/{{totalQuestions}}</span>
        <span class="questionHeader" fxFlex="80">{{currentQuizObj?.question_text}}</span>
    </div>
    <div fxLayout="row" style="height: 280px;" class="grid-sm sm-h100 questionimagecntr">
        <div fxFlex="40" fxLayoutGap="10px" class="center-center col-sm-12 maxw100-sm hidden-lg questionimgsubcntr" appNoRightClick>
            <img mat-card-image class="questionImage marginauto col-sm-10 p_sm_0"
                src={{currentQuizObj?.question_media}} />
        </div>
        <div fxFlex="30" fxLayoutAlign="center center"
            class="col-sm-12 maxw100-sm pt_sm_20 pb_sm_10 pl_sm_0 pr_sm_0 mobcntl">
            <div class="cloclwrap">
                <div class="porel hidden-sm">
                    <progress id="progress" [ngClass]="(currentQuizObj?.time_in_sec<=20)?'clock1':'clock2'"
                        value={{counter}}></progress>
                    <span class="closcktime">{{counter}}</span>
                </div>
                <div class="porel hidden-lg mobclockcntrwrap">
                    <div class="mobclockcntr">
                        <span class="closcktime">{{counter}}</span>
                        <input id="input" type="range" value={{counter}} min="0" max={{timerStringForCountdown}} />
                    </div>
                    <div *ngIf="currentQuizObj?.question_type == 2" fxFlex="30" fxLayoutAlign="center center"
                        class="col-sm-12 multiselectimg maxw100-sm pt_sm_40 pb_sm_40">
                        <span><img src="../../assets/Images/multi-select.png" /><i>Multi Select</i></span>
                    </div>
                </div>
            </div>
            <!-- <span class="counterSpan">{{counter}}</span> -->
        </div>
        <div fxFlex="40" fxLayoutGap="10px" class="center-center col-sm-12 maxw100-sm hidden-sm" appNoRightClick>
            <img mat-card-image class="questionImage marginauto col-sm-10 p_sm_0"
                src={{currentQuizObj?.question_media}} />
        </div>

        <div *ngIf="currentQuizObj?.question_type == 2" fxFlex="30" fxLayoutAlign="center center"
            class="col-sm-12 multiselectimg maxw100-sm pt_sm_40 pb_sm_40 hidden-sm">
            <span><img src="../../assets/Images/multi-select.png" /><i>Multi Select</i></span>
        </div>
    </div>
    <div *ngIf="currentQuizObj?.question_type != 2" class="pl_20 pr_20 prerakDiv whitoutsubopt" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" style="height: 100px;" class="grid-sm H100-sm">
            <button *ngIf="currentQuizObj?.option_1?.length > 0"
                class="mb_10 mr_lg_10 pt_sm_10 pb_sm_10 ml0 minh50 textb col-sm-12" fxFlex="50" mat-raised-button
                color="accent" value={{currentQuizObj?.option_1}} id="A"
                (click)="answerClicked($event)">{{currentQuizObj?.option_1}}</button>
            <button *ngIf="currentQuizObj?.option_2?.length > 0"
                class="mb_10 mb_sm_0  pt_sm_10 pb_sm_10 ml_lg_10 mr0 minh50 col-sm-12" fxFlex="50" mat-raised-button
                color="warn" value={{currentQuizObj?.option_2}} id="B"
                (click)="answerClicked($event)">{{currentQuizObj?.option_2}}</button>
        </div>
        <div fxLayout="row" style="height: 100px;" class="grid-sm H100-sm">
            <button *ngIf="currentQuizObj?.option_3?.length > 0"
                class="mb_10 mr_lg_10 pt_sm_10 pb_sm_10 ml0 minh50 col-sm-12" fxFlex="50" mat-raised-button
                color="primary" value={{currentQuizObj?.option_3}} id="C"
                (click)="answerClicked($event)">{{currentQuizObj?.option_3}}</button>
            <button *ngIf="currentQuizObj?.option_4?.length > 0"
                class="mb_10  ml_lg_10 pt_sm_10 pb_sm_10 mr0 minh50 col-sm-12" fxFlex="50" mat-raised-button
                value={{currentQuizObj?.option_4}} id="D" style="background-color: darkcyan; color: white;"
                (click)="answerClicked($event)">{{currentQuizObj?.option_4}}</button>
        </div>
    </div>
    <div *ngIf="currentQuizObj?.question_type == 2" class="multiSelect pl_20 pr_20 prerakDiv" fxLayout="column"
        fxLayoutGap="2px">
        <div fxLayout="row" style="height: 100px;" class="grid-sm H100-sm">
            <input fxFlex="100" class="checkbox" id={{currentQuizObj?.option_1}} type="checkbox"
                value={{currentQuizObj?.option_1}} data="A" (change)="onCheckboxChange($event)" />
            <label style="background-color: #ffd740;"
                class="mb_10 mb_sm_0 mr_lg_10 ml0 minh50 textleft textb col-sm-12 pt_sm_10 pb_sm_10" fxFlex="50"
                for={{currentQuizObj?.option_1}}><span class="check">✓</span> {{currentQuizObj?.option_1}}</label>

            <input fxFlex="100" class="checkbox" id={{currentQuizObj?.option_2}} type="checkbox"
                value={{currentQuizObj?.option_2}} data="B" (change)="onCheckboxChange($event)" />
            <label style="background-color: #f44336;"
                class="mb_10 mb_sm_0  ml_lg_10 mr0 minh50 textleft col-sm-12 pt_sm_10 pb_sm_10" fxFlex="50"
                for={{currentQuizObj?.option_2}}><span class="check">✓</span>
                {{currentQuizObj?.option_2}}</label>
        </div>
        <div fxLayout="row" style="height: 100px;" class="grid-sm  H100-sm">
            <input fxFlex="100" class="checkbox" id={{currentQuizObj?.option_3}} type="checkbox"
                value={{currentQuizObj?.option_3}} data="C" (change)="onCheckboxChange($event)" />
            <label style="background-color: #673ab7;"
                class="mb_10 mb_sm_0 mr_lg_10 ml0 minh50 textleft col-sm-12 pt_sm_10 pb_sm_10" fxFlex="50"
                for={{currentQuizObj?.option_3}}><span class="check">✓</span>
                {{currentQuizObj?.option_3}}</label>

            <input fxFlex="100" class="checkbox" id={{currentQuizObj?.option_4}} type="checkbox"
                value={{currentQuizObj?.option_4}} data="D" (change)="onCheckboxChange($event)" />
            <label style="background-color: darkcyan; color: white;"
                class="mb_10 ml_lg_10 mr0 minh50 textleft col-sm-12 pt_sm_10 pb_sm_10" fxFlex="50"
                for={{currentQuizObj?.option_4}}><span class="check">✓</span> {{currentQuizObj?.option_4}}</label>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <button class="mb_10" mat-raised-button color="primary" (click)="multiAnsSubmit()">Submit</button>
        </div>
    </div>
</div>