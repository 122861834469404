import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { quizData, QuizData } from '../interfaces/QuizData';
import { DataService } from '../services/data.service';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-quiz-screen',
  templateUrl: './quiz-screen.component.html',
  styleUrls: ['./quiz-screen.component.css', '../plugin-style.css'],
})
export class QuizScreenComponent implements OnInit, OnDestroy {
  quizData: QuizData;
  currentQuizObj: quizData;
  currentQuestion: number = 0;
  totalQuestions: number;
  countDown: Subscription;
  counter;
  tick = 100;
  isAnswerCorrect: boolean = false;
  score: number;
  questionScoreMultiplier: number;
  timer: number = 0.0;
  checkedList = [];
  selectedOptions = [];
  showResultSubscription: Subscription;
  isAnsCorrect: boolean = false;
  timerStringForCountdown;

  constructor(
    private dataService: DataService,
    private router: Router,
    private socketService: SocketioService,
    private locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
    this.quizData = this.dataService.quizData;
    if (this.dataService.currentQuestion == undefined) {
      this.dataService.currentQuestion = 0;
    }
    this.currentQuestion = this.dataService.currentQuestion;
    this.totalQuestions = this.quizData?.quizData?.length;
    this.score = 0;
    this.dataService.isTimeUp = false;
    this.dataService.isPartiallyCorrect = false;
    this.startQuiz();
    this.showResultSubscription = this.socketService
      .ShowResultScreen()
      .subscribe(() => {
        this.score = 0;
        this.timer = 0;
        this.dataService.playerCurrentScore = 0;
        this.dataService.answerStreak = 0;
        this.dataService.currentQuestion++;
        this.dataService.isTimeUp = true;
        this.router.navigate(['/answer-screen']);
      });
  }

  startQuiz(): void {
    if (this.currentQuestion > this.totalQuestions - 1) {
      this.router.navigate(['/final-screen']);
    } else {
      this.currentQuizObj = this.quizData?.quizData[this.currentQuestion];
      this.questionScoreMultiplier = this.currentQuizObj?.credit_coin;
      let counter = this.currentQuizObj?.time_in_sec;
      if(counter == 20){
        this.timerStringForCountdown = "20";
      }
      else if(counter == 30){
        this.timerStringForCountdown = "30";
      }
      this.counter = counter;
      var that = this;
      this.countDown = timer(0, this.tick).subscribe(() => {
        if (counter > 0) {
          if (that.timer % 1000 == 0 && that.timer != 0) {
            --counter;
            that.counter = counter;
          }
          that.timer += 100;
        } else {
          this.countDown.unsubscribe();
          this.showAnswerPage();
        }
      });
    }
  }

  onCheckboxChange(event) {
    if (event.target.checked) {
      this.checkedList.push(event.currentTarget.value);
      this.selectedOptions.push(event.currentTarget.getAttribute('data'));
    } else {
      for (var i = 0; i < 4; i++) {
        if (this.checkedList[i] == event.currentTarget.value) {
          this.checkedList.splice(i, 1);
        }
      }
      for (var i = 0; i < 4; i++) {
        if (
          this.selectedOptions[i] == event.currentTarget.getAttribute('data')
        ) {
          this.selectedOptions.splice(i, 1);
        }
      }
    }
  }

  answerClicked(answer): void {
    this.countDown.unsubscribe();
    if (this.currentQuizObj?.correct_answer == answer.currentTarget.value) {
      const targetMils = this.currentQuizObj?.time_in_sec * 1000;
      const actMils = targetMils - this.timer;
      const actSecs = actMils / 1000;
      this.score = Math.floor(
        Math.max(0, actSecs) * this.questionScoreMultiplier
      );
      this.dataService.playerTotalScore =
        +this.dataService.playerTotalScore + +this.score;
      this.dataService.playerCurrentScore = this.score;
      this.dataService.answerStreak++;
      this.isAnswerCorrect = true;
    } else {
      this.score = 0;
      this.timer = 0;
      this.dataService.playerCurrentScore = 0;
      this.dataService.answerStreak = 0;
      this.isAnswerCorrect = false;
    }
    this.dataService.currentQuestion++;
    this.socketService.giveAnswerToAdmin(
      answer.currentTarget.id,
      this.isAnswerCorrect
    );
    this.router.navigate(['/before-result']);
  }

  showAnswerPage(): void {
    this.score = 0;
    this.timer = 0;
    this.dataService.playerCurrentScore = 0;
    this.dataService.answerStreak = 0;
    this.dataService.currentQuestion++;
    this.dataService.isTimeUp = true;
    this.socketService.giveNoAnswerToAdmin();
    this.router.navigate(['/answer-screen']);
  }

  multiAnsSubmit(): void {
    this.countDown.unsubscribe();
    const targetMils = this.currentQuizObj?.time_in_sec * 1000;
    const actMils = targetMils - this.timer;
    const actSecs = actMils / 1000;
    var ansArr = this.currentQuizObj?.correct_answer?.split(',');
    if (this.checkedList.length > ansArr.length) {
      //More options selected, hence invalid
      this.score = 0;
      this.timer = 0;
      this.dataService.playerCurrentScore = 0;
      this.dataService.answerStreak = 0;
      this.isAnsCorrect = false;
    } else {
      let correctCount = 0;
      let inCorrectCount = 0;
      for (var j = 0; j < this.checkedList.length; j++) {
        if (
          this.currentQuizObj?.correct_answer.indexOf(this.checkedList[j]) !==
          -1
        ) {
          correctCount++;
        } else {
          inCorrectCount++;
        }
      }
      if (inCorrectCount > 0) {
        // Incorrect Option selected, hence invalid
        this.score = 0;
        this.timer = 0;
        this.dataService.playerCurrentScore = 0;
        this.dataService.answerStreak = 0;
        this.isAnsCorrect = false;
      } else if (correctCount == 0) {
        // No correct Option selected, hence invalid
        this.score = 0;
        this.timer = 0;
        this.dataService.playerCurrentScore = 0;
        this.dataService.answerStreak = 0;
        this.isAnsCorrect = false;
      } else if (ansArr.length == correctCount) {
        //Fully correct
        this.score = Math.floor(
          Math.max(0, actSecs) * this.questionScoreMultiplier
        );
        this.dataService.playerTotalScore =
          +this.dataService.playerTotalScore + +this.score;
        this.dataService.playerCurrentScore = this.score;
        this.dataService.answerStreak++;
        this.isAnsCorrect = true;
      } else if (ansArr.length - 1 == correctCount) {
        //Partial Correct
        this.score = Math.floor(
          Math.max(0, actSecs) * (this.questionScoreMultiplier / 2)
        );
        this.dataService.playerTotalScore =
          +this.dataService.playerTotalScore + +this.score;
        this.dataService.playerCurrentScore = this.score;
        this.dataService.answerStreak++;
        this.dataService.isPartiallyCorrect = true;
        this.isAnsCorrect = true;
      } else if (ansArr.length - 2 == correctCount) {
        //Partial correct
        this.score = Math.floor(
          Math.max(0, actSecs) * (this.questionScoreMultiplier / 3)
        );
        this.dataService.playerTotalScore =
          +this.dataService.playerTotalScore + +this.score;
        this.dataService.playerCurrentScore = this.score;
        this.dataService.answerStreak++;
        this.dataService.isPartiallyCorrect = true;
        this.isAnsCorrect = true;
      } else if (ansArr.length - 3 == correctCount) {
        //Partial correct
        this.score = Math.floor(
          Math.max(0, actSecs) * (this.questionScoreMultiplier / 4)
        );
        this.dataService.playerTotalScore =
          +this.dataService.playerTotalScore + +this.score;
        this.dataService.playerCurrentScore = this.score;
        this.dataService.answerStreak++;
        this.dataService.isPartiallyCorrect = true;
        this.isAnsCorrect = true;
      }
    }

    this.dataService.currentQuestion++;
    this.socketService.giveMultiAnswerToAdmin(
      this.selectedOptions,
      this.isAnsCorrect
    );
    this.router.navigate(['/before-result']);
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  ngOnDestroy(): void {
    this.showResultSubscription.unsubscribe();
    this.countDown.unsubscribe();
  }
}
