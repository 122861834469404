import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { QuizData } from './interfaces/QuizData';
import { Observable } from 'rxjs';
import { DataService } from './services/data.service';

@Injectable({
  providedIn: 'root',
})
export class SocketioService {
  socket: any;
  quizStatus: Observable<boolean>;
  constructor(private dataService: DataService) {}

  setUpSocketConnection(): void {
    this.socket = io(environment.SOCKET_ENDPOINT, {
      transports: ['websocket'],
    });

    this.socket.on('reconnect_attempt', () => {
      this.socket.io.opts.transports = ['polling', 'websocket'];
    });
  }

  getQuizStatus(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('QuizStatus', (msg: QuizData) => {
        observer.next(msg);
      });
    });
  }

  checkUserName(name: string) {
    this.socket.emit('checkUsername', name);
  }

  getUserNameStatus(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('getUsernameStatus', (msg: any) => {
        observer.next(msg);
      });
    });
  }

  getQuizStartedByAdmin(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('QuizStartedFromQuestion', (currentQues) => {
        this.dataService.currentQuestion = currentQues;
        observer.next();
      });
    });
  }

  giveAnswerToAdmin(ans: string, isAnswerCorrect): void {
    this.socket.emit('giveAnswerToAdmin', {
      user: this.dataService.userName,
      score: this.dataService.playerTotalScore,
      answerStreak: this.dataService.answerStreak,
      currentAns: ans,
      isAnswerCorrect: isAnswerCorrect,
    });
  }

  giveMultiAnswerToAdmin(ans: any[], isAnswerCorrect): void {
    this.socket.emit('giveMultiAnswerToAdmin', {
      user: this.dataService.userName,
      score: this.dataService.playerTotalScore,
      answerStreak: this.dataService.answerStreak,
      currentAns: ans,
      isAnswerCorrect: isAnswerCorrect,
    });
  }

  giveNoAnswerToAdmin(): void {
    this.socket.emit('giveNoAnswerToAdmin');
  }

  ShowNextQuestion(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('TriggerNextQuestion', () => observer.next());
    });
  }

  ShowResultScreen(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('ShowResultScreen', () => observer.next());
    });
  }

  ShowFinalScreen(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('FinalScreenForUser', () => observer.next());
    });
  }

  getCurrentQuestion(userName: string): void {
    this.socket.emit('GetCurrentQuestion', userName);
  }

  CurrentQuestionFromServer(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('CurrentQuestionFromServer', (obj) => {
        observer.next(obj);
      });
    });
  }

  GetUserRankCall(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('CalculateUserRank', (obj) => observer.next(obj));
    });
  }
}
