<div class="questionOnlyMainDiv">
    <div fxLayout="column" fxFlex="100" *ngIf="isAudioVideo">
        <div class="questionTextDiv" fxLayout="row" fxLayoutAlign="center center" fxFlex="100" fxFlex.md="50"
            fxFlex.gt-md="50">
            <span *ngIf="currentQuizQuestion?.show_video == videoQues.beforeQuestion || currentQuizQuestion?.show_audio == audioQues.beforeQuestion" class="questionSpan">Q.{{currentNumber}} {{currentQuizQuestion?.question_text}}</span>
            <span *ngIf="currentQuizQuestion?.show_video == videoQues.beforeQuestionVideoOnly || currentQuizQuestion?.show_audio == audioQues.beforeQuestionAudioOnly"class="questionSpan">Q.{{currentNumber}} </span>
        </div>
        <div *ngIf="currentQuizQuestion?.show_video == videoQues.beforeQuestion || currentQuizQuestion?.show_video == videoQues.beforeQuestionVideoOnly"
            [ngClass]="isDesktop ? '' : 'Container'" fxLayout="row" fxFlex="100" fxFlex.md="50"
            fxFlex.gt-md="50" fxLayoutAlign="center center">
            <div [ngClass]="isDesktop ? '' : 'iframe-container'">
                <iframe height="315" width="560" [src]='safeURL' frameborder="0" id="iFrameID"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </div>
        </div>
        <div *ngIf="currentQuizQuestion?.show_audio == audioQues.beforeQuestion || currentQuizQuestion?.show_audio == audioQues.beforeQuestionAudioOnly"
            fxLayout="row" fxFlex="100" fxFlex.md="50" fxFlex.gt-md="50" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <ngx-audio-player #audio_player [playlist]="msaapPlaylist" [displayTitle]="msaapDisplayTitle"
                    [autoPlay]="false" muted="muted" [displayVolumeControls]="msaapDisplayVolumeControls"
                    [displayArtist]="msaapDisplayArtist" [displayRepeatControls]="msaapDisplayRepeatControls"
                    [displayDuration]="msaapDisplayDuration" [expanded]="true" [startOffset]="" [endOffset]="">
                </ngx-audio-player>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxFlex="100" *ngIf="!isAudioVideo">
        <div class="questionTextDiv" fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
            <span class="questionSpan">Q.{{currentNumber}} {{currentQuizQuestion?.question_text}}</span>
        </div>
    </div>
</div>