<div *ngIf="isAnswerCorrectB" class="correctMainDiv" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center" class="grid titletext">
        <span *ngIf="!isPartiallyCorrect">CORRECT</span>      
        <span *ngIf="isPartiallyCorrect">Partially Correct</span>  
        <span style="margin: 0;">
            <div class="ui-success">
                <svg viewBox="0 0 87 87" version="1.1" xmlns="https://www.w3.org/2000/svg" xmlns:xlink="https://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Group-3" transform="translate(2.000000, 2.000000)">
                                <circle id="Oval-2" stroke="rgba(165, 220, 134, 0.2)" stroke-width="4" cx="41.5" cy="41.5" r="41.5"></circle>
                                    <circle  class="ui-success-circle" id="Oval-2" stroke="#fff" stroke-width="4" cx="46.5" cy="44" r="41.5"></circle>
                                    <polyline class="ui-success-path" id="Path-2" stroke="#fff" stroke-width="4" points="19 38.8036813 31.1020744 54.8046875 63.299221 28"></polyline>
                            </g>
                        </g>
                </svg>
            </div>
        </span>
        <span style="align-items: center;justify-content: center;display: flex;margin: 0;">Your rank is <span style="width: auto;height: 40px;align-items: center;justify-content: center;display: flex;background: #fff;color: rgb(31, 45, 61);border-radius: 50px;margin-left: 10px;font-size: 30px;">{{selfRank}}</span></span>
        <span *ngIf="selfRank == 1">You are on the Podium!!</span>
        <span *ngIf="selfRank != 1">You are <span style="color: #fff;padding: 0px 5px;background: #f00;border-radius: 5px; font-size: 16px;">{{scoreDiff}}</span> points behind {{otherName}}</span>
        <span class="ansPointsSpan">+{{currentPoints}}</span>
        <!-- <div class="ansPointsDiv" fxLayout="row" fxLayoutAlign="center center">
            <span fxLayoutAlign="center center" class="ansPointsSpan">+{{currentPoints}}</span>
        </div> -->
        <span>{{currentCorrectSlogan}}</span>
        <span>Answer Streak {{answerStreak}}</span>
    </div>
    <div class="userDiv" fxLayout="row" fxLayoutAlign="space-between end">
        <div class="userName" fxLayout="column" style="width: 70%; text-align: left; padding-left: 20px;">
            <span class="userNameSpan" style="color: black;place-content: unset;">{{userName}}</span>
        </div>
        <div class="userTotalPoints" fxLayout="column" style="width: 30%; text-align: right; padding-right: 20px;">
            <span class="userTotalPointsSpan" style="color: black;place-content: unset;">{{totalScore}}</span>
        </div>
    </div>
</div>

<div *ngIf="!isAnswerCorrectB" class="inCorrectMainDiv" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center" class="grid titletext">
        <span *ngIf="!isTimeUp">INCORRECT</span>
        <span *ngIf="isTimeUp">TIME UP!!</span>
        <span style="margin: 0;">
            <div class="ui-error">
                <svg  viewBox="0 0 87 87" version="1.1" xmlns="https://www.w3.org/2000/svg" xmlns:xlink="https://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group-2" transform="translate(2.000000, 2.000000)">
                            <circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" stroke-width="4" cx="41.5" cy="41.5" r="41.5"></circle>
                            <circle  class="ui-error-circle" stroke="#fff" stroke-width="4" cx="41.5" cy="41.5" r="41.5"></circle>
                                <path class="ui-error-line1" d="M22.244224,22 L60.4279902,60.1837662" id="Line" stroke="#fff" stroke-width="3" stroke-linecap="square"></path>
                                <path class="ui-error-line2" d="M60.755776,21 L23.244224,59.8443492" id="Line" stroke="#fff" stroke-width="3" stroke-linecap="square"></path>
                        </g>
                    </g>
                </svg>
            </div>
        </span>
        <span style="align-items: center;justify-content: center;display: flex;margin: 0;">Your rank is <span style="width: auto;height: 40px;align-items: center;justify-content: center;display: flex;background: #fff;color: rgb(31, 45, 61);border-radius: 50px;margin-left: 10px;font-size: 30px;">{{selfRank}}</span></span>
        <span *ngIf="selfRank == 1">You are on the Podium!!</span>
        <span *ngIf="selfRank != 1">You are <span style="color: #fff;padding: 0px 5px;background: #f00;border-radius: 5px; font-size: 16px;">{{scoreDiff}}</span> points behind {{otherName}}</span>
        <span style="background: #c63e4b;padding: 10px;">{{currentInCorrectSlogan}}</span>
        <div class="userDiv" fxLayout="row" fxLayoutAlign="space-between end">
            <div class="userName" fxLayout="column" style="width: 70%; text-align: left; padding-left: 20px;">
                <span class="userNameSpan" style="color: black;place-content: unset;padding: 0;">{{userName}}</span>
            </div>
            <div class="userTotalPoints" fxLayout="column" style="width: 30%; text-align: right; padding-right: 20px;">
                <span class="userTotalPointsSpan" style="color: black;place-content: unset;padding: 0;">{{totalScore}}</span>
            </div>
        </div>
    </div>
</div>